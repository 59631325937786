<template>
  <docs-layout> 
    <div class="space-y-4 pb-10 w-full text-left">
      <p class="font-bold">Sample</p>
      <div class="w-1/4">
        <asom-input-textarea 
          v-model="sampleInput"
          placeholder="Placeholder"
        />
      </div>
      <p>Value: {{sampleInput}}</p>
    </div>
    <div class="space-y-4 pb-10 w-full text-left">
      <p class="font-bold">Textarea width</p>
      <p>Width of textarea, along with its responsiveness, is determined by container width. </p>
      <div class="w-1/4">
        <asom-input-textarea 
          value="Width 1/4"
          placeholder="Placeholder"
        />
      </div>
      <div class="w-1/2">
        <asom-input-textarea 
          value="Width 1/2"
          placeholder="Placeholder"
        />
      </div>
      <div class="w-2/3">
        <asom-input-textarea
          value="Width 2/3"
          placeholder="Placeholder"
        />
      </div>
      <div class="w-full">
        <asom-input-textarea
          value="Full width"
          placeholder="Placeholder"
        />
      </div>
    </div>
    <div class="space-y-4 pb-10 w-1/2 text-left">
      <p class="font-bold">Textarea states</p>
      <asom-input-textarea v-for="(s, i) in states"
        :key="i"
        :state="s"
        :value="`Input state ${s}`"
        placeholder="Placeholder"
      />
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'

export default {
  components: {
    DocsLayout
  },
  data() {
    return {
      sampleInput: '',
      states: [ 'default', 'error' ]
    }
  },
}
</script>